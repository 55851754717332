import React from "react";

function SvgManage() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1574 12.9833H6.50927C5.86902 12.9833 5.35 12.4643 5.35 11.8241V6.50927C5.35 5.86902 5.86902 5.35 6.50927 5.35H10.1574C10.7976 5.35 11.3167 5.86902 11.3167 6.50927V11.8241C11.3167 12.4643 10.7976 12.9833 10.1574 12.9833ZM10.1574 19.65H6.50927C5.86902 19.65 5.35 19.131 5.35 18.4907V16.5093C5.35 15.869 5.86902 15.35 6.50927 15.35H10.1574C10.7976 15.35 11.3167 15.869 11.3167 16.5093V18.4907C11.3167 19.131 10.7976 19.65 10.1574 19.65ZM18.4907 19.65H14.8426C14.2024 19.65 13.6833 19.131 13.6833 18.4907V13.1759C13.6833 12.5357 14.2024 12.0167 14.8426 12.0167H18.4907C19.131 12.0167 19.65 12.5357 19.65 13.1759V18.4907C19.65 19.131 19.131 19.65 18.4907 19.65ZM13.6833 8.49073V6.50927C13.6833 5.86902 14.2024 5.35 14.8426 5.35H18.4907C19.131 5.35 19.65 5.86902 19.65 6.50927V8.49073C19.65 9.13098 19.131 9.65 18.4907 9.65H14.8426C14.2024 9.65 13.6833 9.13098 13.6833 8.49073Z" stroke="#AEAEAE" stroke-width="0.7" />
        </svg>
    );
}

export default SvgManage;
