import React from "react";

function SvgFee() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2061 5.39802L4.46734 8.58155C3.65695 8.80133 3.17603 9.64441 3.39317 10.4646L5.35904 17.8903C5.57618 18.7105 6.40917 19.1973 7.21956 18.9775L18.9583 15.794C19.7687 15.5742 20.2496 14.7311 20.0325 13.9109L18.0666 6.48521C17.8495 5.66499 17.0165 5.17824 16.2061 5.39802Z" stroke="#AEAEAE" strokeWidth="0.710807" strokeLinecap="square" />
      <path d="M4.76953 15.6626L19.443 11.6832" stroke="#AEAEAE" strokeWidth="0.710807" strokeLinecap="square" />
    </svg>
  );
}

export default SvgFee;
