import React from "react";

function SvgNews() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1928 5.1731H5V19.3659H19.1928V5.1731Z"
        stroke="#AEAEAE"
        strokeWidth="0.709641"
        strokeLinecap="square"
      />
      <path
        d="M11.3867 8.72128H15.6446M11.3867 12.2695H15.6446M11.3867 15.8177H15.6446"
        stroke="#AEAEAE"
        strokeWidth="0.709641"
        strokeLinecap="square"
      />
      <path
        d="M8.37109 8.72128H9.25815M8.37109 12.2695H9.25815M8.37109 15.8177H9.25815"
        stroke="#AEAEAE"
        strokeWidth="0.709641"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default SvgNews;
