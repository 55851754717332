import React from "react";

function SvgQr() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5195 10.6974V5.17798H5.0001V10.6974H10.5195Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M7.36629 7.54349H8.15479V8.33198H7.36629V7.54349Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M10.5195 19.3711V13.8516H5.0001V19.3711H10.5195Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M8.1547 16.2165H7.36621V17.005H8.1547V16.2165Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M14.4628 18.5825H13.6743V19.371H14.4628V18.5825Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M16.8276 16.2165H16.0391V17.005H16.8276V16.2165Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M14.4628 13.8516H13.6743V14.6401H14.4628V13.8516Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M19.1938 16.2165H18.4053V17.005H19.1938V16.2165Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M19.1938 13.8516H18.4053V14.6401H19.1938V13.8516Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M19.1938 18.5825H18.4053V19.371H19.1938V18.5825Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M19.1929 10.6974V5.17798H13.6734V10.6974H19.1929Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
      <path
        d="M16.8276 7.54349H16.0391V8.33198H16.8276V7.54349Z"
        stroke="#AEAEAE"
        strokeWidth="0.78849"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default SvgQr;
