import React from "react";

const SvgProfile = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.31934 17.5624C8.99934 16.8336 10.0793 16.2264 10.5593 15.7405C11.2793 15.0118 9.11934 15.0118 9.11934 11.3681C9.11934 8.93904 10.0793 7.72449 11.9993 7.72449C13.9193 7.72449 14.8793 8.93904 14.8793 11.3681C14.8793 15.0118 12.7193 15.0118 13.4393 15.7405C13.9193 16.2264 14.9993 16.8336 16.6793 17.5624" stroke="#AEAEAE" strokeWidth="0.781888" strokeLinecap="round" />
      <path d="M11.9998 19.3842C15.9763 19.3842 19.1998 16.1216 19.1998 12.0969C19.1998 8.07222 15.9763 4.80957 11.9998 4.80957C8.02335 4.80957 4.7998 8.07222 4.7998 12.0969C4.7998 16.1216 8.02335 19.3842 11.9998 19.3842Z" stroke="#AEAEAE" strokeWidth="0.781888" strokeLinecap="square" />
    </svg>

  );
};

export default SvgProfile;
