import React from "react";

const SvgLiveMatches = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1284 11.869C11.7221 14.4237 10.1609 16.236 9.80606 17.0639C8.73613 11.2557 5.11481 8.92484 2 8.0105"
        stroke="#AEAEAE"
        strokeWidth="0.709641"
        strokeDasharray="1.42 1.42"
      />
      <path
        d="M15.1665 7.23162C16.3505 6.88002 17.8959 7.24398 19.1632 8.2912C20.4503 9.35481 21.0993 10.8331 20.9507 12.0742"
        stroke="#AEAEAE"
        strokeWidth="0.567713"
      />
      <path
        d="M17.9359 12.7279C17.6383 11.7753 17.9002 10.3135 18.707 8.91614C19.5114 7.52296 20.6419 6.56676 21.6138 6.34448"
        stroke="#AEAEAE"
        strokeWidth="0.567713"
      />
      <path
        d="M18.4592 5C18.3888 5.75882 18.0977 6.6509 17.5941 7.52318C16.8393 8.83051 15.8074 9.74667 14.9307 9.99018"
        stroke="#AEAEAE"
        strokeWidth="0.567713"
      />
      <path
        d="M18.677 12.8061C20.8325 12.8061 22.58 11.0586 22.58 8.90303C22.58 6.74744 20.8325 5 18.677 5C16.5214 5 14.7739 6.74744 14.7739 8.90303C14.7739 11.0586 16.5214 12.8061 18.677 12.8061Z"
        stroke="#AEAEAE"
        strokeWidth="0.567713"
        strokeLinecap="square"
      />
      <line
        x1="3.41943"
        y1="18.1283"
        x2="18.3219"
        y2="18.1283"
        stroke="#AEAEAE"
        strokeWidth="0.709641"
      />
    </svg>
  );
};

export default SvgLiveMatches;
