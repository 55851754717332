import React from "react";

function SvgTeam() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.70508 9.07789C7.82847 8.43533 10.6003 9.10049 12.8731 11.0143C15.1815 12.9581 16.3455 15.6598 16.0788 17.928" stroke="#AEAEAE" stroke-width="0.713291" />
            <path d="M10.6705 19.1226C10.1366 17.3817 10.6065 14.7103 12.0534 12.1566C13.496 9.61049 15.5237 7.86301 17.2667 7.45679" stroke="#AEAEAE" stroke-width="0.713291" />
            <path d="M11.6101 5C11.4838 6.38677 10.9618 8.01708 10.0586 9.6112C8.7049 12.0004 6.85406 13.6747 5.28174 14.1197" stroke="#AEAEAE" stroke-width="0.713291" />
            <path d="M12 19.2658C15.866 19.2658 19 16.0723 19 12.1329C19 8.19351 15.866 5 12 5C8.13401 5 5 8.19351 5 12.1329C5 16.0723 8.13401 19.2658 12 19.2658Z" stroke="#AEAEAE" stroke-width="0.713291" stroke-linecap="square" />
        </svg>
    );
}

export default SvgTeam;
