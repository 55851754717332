import React from "react"
import { useImage } from "../../hooks/useImage";

const DynamicImage = ({ imageKey }) => {
    const image = useImage(imageKey);
    return (
        <>
            {image}
        </>
    )
}

export default DynamicImage