import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {
  SvgCommunications,
  SvgFee,
  SvgLiveMatches,
  SvgManage,
  SvgMessages,
  SvgNews,
  SvgProfile,
  SvgQr,
  SvgTarget,
  SvgTeam,
} from "../svg/icons";

const images = {
  // HOME
  // Accordion section
  "home-accordion-0-background": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/gernika-kesb-taldea.jpg"
        alt=""
        objectFit="cover"
        objectPosition="50% 50%"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-0-app": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/gernika-kesb-app.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-0-background": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/gernika-kesb-taldea.jpg"
        alt=""
        objectFit="cover"
        objectPosition="50% 50%"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-0-app": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/gernika-kesb-app.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-1-background": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/zornotza-zst-taldea.jpg"
        alt=""
        objectFit="cover"
        objectPosition="50% 50%"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-1-app": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/zornotza-zst-app.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-2-background": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/aurrera-ke-taldea.jpg"
        alt=""
        objectFit="cover"
        objectPosition="50% 50%"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-2-app": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/aurrera-ke-app.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-3-app": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/sd-amorebieta-app.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-3-background": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/sd-amorebieta-taldea.jpg"
        alt=""
        objectFit="cover"
        objectPosition="50% 50%"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },

  "home-accordion-5-app": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/barakaldo-cf-app.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "home-accordion-5-background": {
    img: (
      <StaticImage
        src="../../../assets/images/home/section-accordion/barakaldo-cf-taldea.jpg"
        alt=""
        objectFit="cover"
        objectPosition="50% 50%"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  // Clubs icons
  "gernika-kesb-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/gernika-kesb-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "bilbao-basket-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/bilbao-basket-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "sda-amorebieta-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/sda-amorebieta-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "gipuzkoa-basket-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/gipuzkoa-basket-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "ardoi-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/ardoi-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "valle-de-egues-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/valle-de-egues-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "dinamo-sanjuan-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/dinamo-sanjuan-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "araberri-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/araberri-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "aurrera-ondarroa-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/aurrera-ondarroa-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "sestao-boleibola-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/sestao-boleibola-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "barakaldo-cf-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/barakaldo-cf-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "zornotza-saskibaloi-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/zornotza-saskibaloi-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "zazpi-landa-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/zazpi-landa-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "ausarta-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/ausarta-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "bizkerre-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/bizkerre-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "kurene-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/kurene-logo.png"
        alt=""
        objectFit="contain"
        placeholder="none"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },

  // Clubs icons with background
  "bilbao-basket-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/bilbao-basket-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "valle-de-egues-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/valle-de-egues-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "zornotza-saskibaloi-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/zornotza-st-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "sda-amorebieta-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/sd-amorebieta-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "gipuzkoa-basket-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/gipuzkoa-basket-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "zazpi-landa-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/zazpi-landa-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "gernika-kesb-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/lointek-gernika-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },

  "ardoi-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/ardoi-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "araberri-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/araberri-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "aurrera-ondarroa-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/aurrera-ondarroa-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "barakaldo-cf-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/barakaldo-cf-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "dinamo-sanjuan-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/dinamo-sanjuan-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "sestao-boleibola-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/sestao-boleibola-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "kurene-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/kurene-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "bizkerre-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/bizkerre-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "ausarta-background-icon": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/ausarta-logo-background.jpg"
        alt=""
        objectFit="contain"
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-app-main": {
    img: (
      <StaticImage
        src="../../../assets/images/app/app-match-main-thumbnail.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-app-detail": {
    img: (
      <StaticImage
        src="../../../assets/images/app/app-match-info-thumbnail.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-app-news": {
    img: (
      <StaticImage
        src="../../../assets/images/app/app-match-news-thumbnail.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-app-main-eus": {
    img: (
      <StaticImage
        src="../../../assets/images/app/app-match-main-thumbnail-eus.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-app-detail-eus": {
    img: (
      <StaticImage
        src="../../../assets/images/app/app-match-info-thumbnail-eus.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-app-news-eus": {
    img: (
      <StaticImage
        src="../../../assets/images/app/app-match-news-thumbnail-eus.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-manager-main": {
    img: (
      <StaticImage
        src="../../../assets/images/app/manager-members-thumbnail.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-manager-detail": {
    img: (
      <StaticImage
        src="../../../assets/images/app/manager-members-detail-thumbnail.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-manager-new": {
    img: (
      <StaticImage
        src="../../../assets/images/app/manager-members-new-thumbnail.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "live-matches-icon": {
    img: <SvgLiveMatches />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "push-messages-icon": {
    img: <SvgMessages />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "digital-card-icon": {
    img: <SvgQr />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "news-icon": {
    img: <SvgNews />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "team-icon": {
    img: <SvgTeam />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "profile-icon": {
    img: <SvgProfile />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "fee-icon": {
    img: <SvgFee />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "communications-icon": {
    img: <SvgCommunications />,
    alts: {
      es: "",
      eu: "",
    },
  },
  "manage-icon": {
    img: <SvgManage />,
    alts: {
      es: "",
      eu: "",
    },
  },
  // Clubs app screenshots
  "screenshot-bilbao-basket-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bilbao-basket-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bilbao-basket/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gipuzkoa-basket-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gipuzkoa-basket/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sda-amorebieta-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sda-amorebieta/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-gernika-kesb-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/gernika-kesb/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },

  "screenshot-ardoi-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ardoi-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ardoi/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-aurrera-ondarroa-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/aurrera-ondarroa/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-barakaldo-cf-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/barakaldo-cf/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-dinamo-sanjuan-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/dinamo-sanjuan/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
 
  "screenshot-sestao-boleibola-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-sestao-boleibola-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/sestao-boleibola/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-valle-de-egues-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/valle-de-egues/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zazpi-landa-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zazpi-landa/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-zornotza-saskibaloi-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/zornotza-saskibaloi/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-araberri-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/araberri/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  
  "screenshot-ausarta-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },

  "screenshot-ausarta-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-ausarta-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/ausarta/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  





   "screenshot-kurene-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },


  "screenshot-kurene-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-kurene-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/kurene/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },




   "screenshot-bizkerre-eu-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/eu/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-eu-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/eu/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-eu-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/eu/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-eu-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/eu/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-eu-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/eu/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-eu-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/eu/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },

  "screenshot-bizkerre-es-0": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/es/1_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-es-1": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/es/2_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-es-2": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/es/3_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-es-3": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/es/4_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-es-4": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/es/5_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "screenshot-bizkerre-es-5": {
    img: (
      <StaticImage
        src="../../../assets/images/clubs/bizkerre/screenshots/es/6_750x1334.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "google-play": {
    img: (
      <StaticImage
        src="../../../assets/images/google-play-icon.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "app-store": {
    img: (
      <StaticImage
        src="../../../assets/images/app-store-icon.png"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "about-image-0.1": {
    img: (
      <StaticImage
        src="../../../assets/images/about/araberri-botando-balon.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "about-image-1": {
    img: (
      <StaticImage
        src="../../../assets/images/about/abrazo-jugadoras.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
  "about-image-2": {
    img: (
      <StaticImage
        src="../../../assets/images/about/jugador-postura-pase-largo.jpg"
        alt=""
        objectFit="contain"
        quality={90}
      />
    ),
    alts: {
      es: "",
      eu: "",
    },
  },
};

const useImage = (key, isGrid) => {
  //index only for array of images
  const { i18n } = useTranslation();
  const lang = i18n.language;

  if (!images[key]) {
    console.log("⚠️ No se ha encontrado la imagen con key: " + key);
    return null;
  }
  if (isGrid) {
    //TODO: map en cada array para cambiar alts
    return images[key];
  }

  if (Array.isArray(images[key])) {
    const imagesArr = images[key].map((element) => {
      return React.cloneElement(element.img, {
        alt: element.alts[lang],
      });
    });
    return imagesArr;
  } else {
    if (!images[key].alts) {
      console.log("⚠️ Falta la propiedad alt en el key: " + key);
      return null;
    }
    const element = images[key].img;
    const newElement = React.cloneElement(element, {
      alt: images[key].alts[lang],
    });

    return key ? newElement : null;
  }
};

export { useImage };
