import React from "react";

function SvgCommunications() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.14723 9.34654C8.72187 9.34654 10.2965 9.34654 11.8711 9.34654C13.5889 9.34654 15.593 7.8977 17.8834 5V18.0396C15.593 15.1419 13.5889 13.6931 11.8711 13.6931C10.2965 13.6931 8.72187 13.6931 7.14723 13.6931C5.96135 13.6931 5 12.7201 5 11.5198C5 10.3196 5.96135 9.34654 7.14723 9.34654Z" stroke="#AEAEAE" strokeWidth="0.710807" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M7.57666 13.6935L9.29444 18.9093H12.73L11.0122 13.6935H7.57666Z" stroke="#AEAEAE" strokeWidth="0.710807" strokeLinecap="square" />
      <path d="M11.0127 9.34656V13.6931" stroke="#AEAEAE" strokeWidth="0.710807" strokeLinecap="square" />
    </svg>
  );
}

export default SvgCommunications;
