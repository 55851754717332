import React from "react";

function SvgTarget() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9631 17.7025C15.0417 17.7025 17.5375 15.1765 17.5375 12.0605C17.5375 8.94447 15.0417 6.41846 11.9631 6.41846C8.88442 6.41846 6.38867 8.94447 6.38867 12.0605C6.38867 15.1765 8.88442 17.7025 11.9631 17.7025Z" stroke="#AEAEAE" strokeWidth="0.746342" strokeLinecap="square" />
      <path d="M11.9634 4V8.836" stroke="#AEAEAE" strokeWidth="0.746342" strokeLinecap="square" />
      <path d="M11.9634 15.2847V20.1207" stroke="#AEAEAE" strokeWidth="0.746342" strokeLinecap="square" />
      <path d="M4 12.0604H8.77805" stroke="#AEAEAE" strokeWidth="0.746342" strokeLinecap="square" />
      <path d="M15.1489 12.0604H19.927" stroke="#AEAEAE" strokeWidth="0.746342" strokeLinecap="square" />
      <path d="M11.9638 12.867C12.4036 12.867 12.7602 12.5061 12.7602 12.061C12.7602 11.6159 12.4036 11.255 11.9638 11.255C11.524 11.255 11.1675 11.6159 11.1675 12.061C11.1675 12.5061 11.524 12.867 11.9638 12.867Z" stroke="#AEAEAE" strokeWidth="0.746342" strokeLinecap="square" />
    </svg>
  );
}

export default SvgTarget;
