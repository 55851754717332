import React from "react";

function SvgMessages() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9294 16.5416L8.17176 19.7735V16.5416H5V6.03763H19.2729V16.5416H12.9294Z"
        stroke="#AEAEAE"
        strokeWidth="0.709641"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default SvgMessages;
